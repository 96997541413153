<div class="intro-bg">
  <div class="intro">
    <h1>
      <span>{{ intro }}</span>
    </h1>
  </div>
</div>

<div
class="btn"
  [ngClass]="mobileNav ? 'navbar-toggler-active' : 'navbar-toggler'"
  *ngIf="mobileNav"
  (click)="toggleMobileMenu()"
>
  Menu
</div>
<div class="mbnav">
  <div
    class="mobile-nav"
    [ngClass]="mobileNav ? 'mobile-nav-active' : 'mobile-nav'"
  ></div>
  <div
    class="mobile-nav"
    [ngClass]="mobileNav ? 'mobile-nav-active' : 'mobile-nav'"
  ></div>
  <div
    class="mobile-nav"
    [ngClass]="mobileNav ? 'mobile-nav-active' : 'mobile-nav'"
  ></div>
  <div
    class="mobile-nav"
    [ngClass]="mobileNav ? 'mobile-nav-active' : 'mobile-nav'"
  ></div>
</div>

<div class="mbnavoptions" *ngIf="mobileNav">
  <div class="mbnavoption">
    <a
      routerLink="/home"
      routerLinkActive="mbnavoption-active"
      (click)="toggleMobileMenu()"
      >Home</a
    >
  </div>
  <div class="mbnavoption">
    <a
      routerLink="/about"
      routerLinkActive="mbnavoption-active"
      (click)="toggleMobileMenu()"
      >About</a
    >
  </div>
  <div class="mbnavoption">
    <a
      routerLink="/events"
      routerLinkActive="mbnavoption-active"
      (click)="toggleMobileMenu()"
      >Events</a
    >
  </div>
  <div class="mbnavoption">
    <a
      routerLink="/info"
      routerLinkActive="mbnavoption-active"
      (click)="toggleMobileMenu()"
      >Info</a
    >
  </div>
</div>

<div class="navigation-wrap bg-light start-header start-style">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <nav class="navbar navbar-expand-md navbar-light">
          <a class="navbar-brand" routerLink="/home"
            ><img src="assets/images/kouterlogo.svg" alt="kouterlogo"
          /></a>

          <div
            class="navbar-toggler"
            type="button"
            [ngClass]="mobileNav ? 'navbar-toggler-active' : 'navbar-toggler'"
            (click)="toggleMobileMenu()"
          >
            Menu
          </div>

          <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav ml-auto py-4 py-md-0">
              <li class="nav-item">
                <a routerLink="/home" routerLinkActive="active" class="nav-link"
                  >Home</a
                >
              </li>

              <li class="nav-item">
                <a
                  routerLink="/about"
                  routerLinkActive="active"
                  class="nav-link"
                  >About</a
                >
              </li>
              <li class="nav-item">
                <a
                  class="nav-link"
                  routerLink="/events"
                  routerLinkActive="active"
                  >De Kouter Events</a
                >
              </li>

              <li class="nav-item">
                <a class="nav-link" routerLink="/info" routerLinkActive="active"
                  >Info</a
                >
              </li>
            </ul>
          </div>
        </nav>
      </div>
    </div>
  </div>
</div>

<router-outlet></router-outlet>

<div [className]="footerHome ? 'socials-footer-home' : 'socials-footer'">
  <footer class="footer">
    <div class="container footer-icons">
      <a href="https://www.instagram.com/dekouterevents/"
        ><i class="fab fa-instagram"></i
      ></a>

      <a href="https://www.facebook.com/jcdekouter"
        ><i class="fab fa-facebook-f"></i
      ></a>

      <a href="mailto:info@dekouter.be?body=Hey there!"
        ><i class="far fa-envelope"></i
      ></a>
    </div>
    <div class="copyright">&#9400; {{ currentYear }} JC De Kouter VZW</div>
  </footer>
</div>
