import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { base } from 'src/base';

@Injectable({
  providedIn: "root"
})
export class TeamService {
  constructor(private http: HttpClient) {}

  public getMembers(): any {
    return this.http.get(base.baseUrlApi + 'members');
  }
}
