import { Component, OnInit, ElementRef } from "@angular/core";
import * as $ from "jquery";
import { AppComponent } from "../app.component";

@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.scss"]
})
export class HomeComponent implements OnInit {
  constructor(
    private elementRef: ElementRef,
    private appComponent: AppComponent
  ) {}

  homevisited: boolean;

  ngOnInit(): void {
    this.appComponent.footerHome = true;
    this.homevisited = this.appComponent.homeVisited;
  }
}
