<div class="container">
  <h1
    data-aos="fade-down"
    data-aos-duration="500"
    data-aos-delay="100"
    data-aos-easing="ease-in-out"
  >
    Kouter info
  </h1>

  <div class="row info">
    <div
      class="col-md-6 col-sm-12"
      data-aos="fade-down"
      data-aos-duration="500"
      data-aos-delay="150"
      data-aos-easing="ease-in-out"
    >
      <h3 class="font-weight-bold">Openingsuren</h3>
      <p class="margin">Zondag - Donderdag</p>
      <p class="font-italic">Closed</p>

      <p class="mg">Vrijdag - Zaterdag</p>
      <p class="font-italic">20h00 - 02h00</p>

      <h3 class="font-weight-bold firstpar">Get in touch</h3>
      <p class="margin">Cornelius A Lapideplein 3</p>
      <p>3950 Bocholt</p>
      <p>0472 74 99 40</p>
      <p class="icons">
        <a href="tel:+32472749940"><i class="fas fa-phone"></i></a>
        <a href="https://www.facebook.com/jcdekouter"
          ><i class="fab fa-facebook-messenger"></i
        ></a>
        <a href="mailto:info@dekouter.be?body=Hey there!"
          ><i class="far fa-envelope"></i
        ></a>
        <a
          href="https://www.google.com/maps/dir//De+Kouter,+Cornelius+A+Lapideplein+3,+3950+Bocholt/@51.1738932,5.5765172,17z/data=!4m8!4m7!1m0!1m5!1m1!1s0x47c0d487362b0afb:0x1b33e0837f0db625!2m2!1d5.5787059!2d51.1738899"
          ><i class="fas fa-route"></i
        ></a>
      </p>
    </div>
    <div class="col-md-6 col-sm-12">
      <iframe
        src="https://jckouter.typeform.com/to/l7Tuz0"
        style="width:100%;height:80% class='typeform-html'"
      ></iframe>
    </div>
  </div>
</div>
