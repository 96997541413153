import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { RouterModule, Routes } from "@angular/router";
import { TeamComponent } from "./team/team.component";
import { HomeComponent } from "./home/home.component";
import { AboutComponent } from "./about/about.component";
import { EventsComponent } from "./events/events.component";
import { InfoComponent } from "./info/info.component";
import { DetailsComponent } from "./team/details/details.component";
import { HashLocationStrategy, LocationStrategy } from "@angular/common";
import { ErrorComponent } from "./error/error.component";

const appRoutes: Routes = [
  { path: "home", component: HomeComponent },
  { path: "about", component: AboutComponent },
  { path: "events", component: EventsComponent },
  { path: "info", component: InfoComponent },
  {
    path: "**",
    component: HomeComponent,
  },
];

@NgModule({
  declarations: [
    AppComponent,
    TeamComponent,
    HomeComponent,
    AboutComponent,
    EventsComponent,
    InfoComponent,
    DetailsComponent,
    ErrorComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    RouterModule.forRoot(appRoutes, { enableTracing: false }),
  ],
  providers: [{ provide: LocationStrategy, useClass: HashLocationStrategy }],
  bootstrap: [AppComponent],
})
export class AppModule { }
