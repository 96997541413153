import { Component, OnInit } from "@angular/core";
import { TeamService } from "./service/team.service";
import * as AOS from "aos";
import * as $ from "jquery";
import { AppComponent } from "../app.component";
import { base } from 'src/base';

@Component({
  selector: "app-team",
  templateUrl: "./team.component.html",
  styleUrls: ["./team.component.scss"]
})
export class TeamComponent implements OnInit {
  constructor(
    private teamService: TeamService,
    private appComponent: AppComponent
  ) {
    function shuffle(a) {
      var j, x, i;
      for (i = a.length - 1; i > 0; i--) {
        j = Math.floor(Math.random() * (i + 1));
        x = a[i];
        a[i] = a[j];
        a[j] = x;
      }
      return a;
    }

    this.getTeamMembers();

    shuffle(this.teamMembers);
  }

  teamMembers: Array<{
    firstname: string;
    lastname: string;
    function: string;
    cartoon: any;
  }> = [];

  public base = base;

  test = 100;

  getTeamMembers() {
    this.teamService.getMembers().subscribe(members => {
      this.teamMembers = members;
    });
  }

  ngOnInit(): void {
    AOS.init();
    this.appComponent.footerHome = false;
    this.appComponent.homeVisited = true;
  }
}
