<div class="container">
  <h1
    data-aos="fade-down"
    data-aos-duration="500"
    data-aos-delay="100"
    data-aos-easing="ease-in-out"
  >
    De Kouter Events
  </h1>

  <div
    data-aos="fade-down"
    data-aos-duration="500"
    data-aos-delay="150"
    data-aos-easing="ease-in-out"
  >
    <p class="firstpar">
      Jaarlijks organiseert De Kouter verschillende spectaculaire optredens die
      voor jong en oud zijn. Zo hebben we artiesten van Novastar tot lokale
      bandjes op ons podium gehad.
    </p>

    <img
      class="first-img"
      data-scroll-speed="4"
      src="assets/images/compactdiskdummies.jpg"
      alt="compactdiskdummies"
    />

    <img
      class="second-img"
      data-scroll-speed="2"
      src="assets/images/novastar.jpg"
      alt="novastar"
    />

    <img
      class="third-img"
      data-scroll-speed="2"
      src="assets/images/halloweenparty.jpg"
      alt="halloweenparty"
    />

    <div
      data-aos="fade-up"
      data-aos-duration="500"
      data-aos-delay="100"
      data-aos-easing="ease-in-out"
    >
      <p>
        Hou zeker onze facebookpagina in de gaten voor naderende evenementen.
      </p>
      <p>
        Wil jij eens gebruik maken van onze prachtige geluidsinstallatie? Of een
        tof evenement organiseren?
      </p>

      <p>
        Contacteer ons dan even op
        <a href="mailto:info@dekouter.be?body=Hey there!">info@dekouter.be</a>
        en dan zullen we bekijken wat we kunnen betekenen.
      </p>
    </div>
  </div>
</div>
