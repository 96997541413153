import { Component, OnInit } from "@angular/core";
import * as AOS from "aos";
import * as typeformEmbed from "@typeform/embed";
import { AppComponent } from "../app.component";

@Component({
  selector: "app-info",
  templateUrl: "./info.component.html",
  styleUrls: ["./info.component.scss"]
})
export class InfoComponent implements OnInit {
  constructor(private appComponent: AppComponent) {}

  mobileNav: boolean = false;

  ngOnInit(): void {
    AOS.init();
    this.appComponent.homeVisited = true;
    this.appComponent.footerHome = false;
  }
}
