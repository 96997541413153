import { Component } from "@angular/core";
import { Router } from "@angular/router";
import * as $ from "jquery";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent {
  title = "kouter";
  mobileNav: boolean = false;
  footerHome: boolean = false;
  homeVisited: boolean = false;

  currentYear: number;

  constructor(private router: Router) {}

  intros: Array<any> = [
    "welkom",
    "proost",
    "benvenuto",
    "добре дошли",
    "velkommen",
    "bienvenido",
    "welcome",
    "歡迎",
    "hoşgeldiniz",
    "gesondheid",
    "santé",
    "saluti",
    "prosit",
    "skål",
    "cheers",
    "cin cin",
  ];
  intro: string;

  toggleMobileMenu() {
    this.mobileNav = !this.mobileNav;
  }

  ngOnInit(): void {
    this.router.navigate(["/home"]);
    this.footerHome = true;

    this.intro = this.intros[Math.floor(Math.random() * this.intros.length)];

    this.currentYear = new Date().getFullYear();
  }
}
