<div class="container">
  <h1
    data-aos="fade-down"
    data-aos-duration="500"
    data-aos-delay="100"
    data-aos-easing="ease-in-out"
  >
    About us
  </h1>

  <div
    data-aos="fade-down"
    data-aos-duration="500"
    data-aos-delay="150"
    data-aos-easing="ease-in-out"
  >
    <p class="firstpar">
      Jeugdhuis De Kouter draait weer op volle toeren dankzij onze toffe
      kernleden.
    </p>
    <p>
      Vrijdag en zaterdag kan je in het jeugdhuis terecht voor een frisse pint,
      een spelletje kicker of een goede babbel.
    </p>

    <video
      playsinline="playsinline"
      autoplay="autoplay"
      muted="muted"
      loop="loop"
      class="third-img"
      data-scroll-speed="2"
    >
      <source src="assets/images/tap.mp4" type="video/mp4" />
    </video>

    <div class="second-img" data-scroll-speed="1.5">
      <svg
        width="309"
        height="383"
        viewBox="0 0 309 383"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="309" height="383" fill="#ECECEC" />
        <path
          d="M130.316 224.158H124.842V229.632H130.316V224.158Z"
          fill="#CA9158"
        />
        <path
          d="M124.842 218.684H119.368V224.158H124.842V218.684Z"
          fill="#CA9158"
        />
        <path
          d="M130.316 240.614H124.842V246.088H130.316V240.614Z"
          fill="#CA9158"
        />
        <path
          d="M130.316 235.14H124.842V240.614H130.316V235.14Z"
          fill="#CA9158"
        />
        <path
          d="M130.316 218.684H124.842V224.158H130.316V218.684Z"
          fill="#CA9158"
        />
        <path
          d="M124.842 224.158H119.368V229.632H124.842V224.158Z"
          fill="#CA9158"
        />
        <path
          d="M124.842 235.14H119.368V240.614H124.842V235.14Z"
          fill="#CA9158"
        />
        <path
          d="M124.842 229.632H119.368V235.14H124.842V229.632Z"
          fill="#CA9158"
        />
        <path
          d="M124.842 213.21H119.368V218.684H124.842V213.21Z"
          fill="#CA9158"
        />
        <path
          d="M130.316 229.632H124.842V235.14H130.316V229.632Z"
          fill="#CA9158"
        />
        <path
          d="M130.316 213.21H124.842V218.684H130.316V213.21Z"
          fill="#CA9158"
        />
        <path
          d="M124.842 207.702H119.368V213.21H124.842V207.702Z"
          fill="#CA9158"
        />
        <path
          d="M124.842 240.614H119.368V246.088H124.842V240.614Z"
          fill="#CA9158"
        />
        <path
          d="M130.316 207.702H124.842V213.21H130.316V207.702Z"
          fill="#CA9158"
        />
        <path
          d="M124.842 185.772H119.368V191.246H124.842V185.772Z"
          fill="#CA9158"
        />
        <path
          d="M124.842 202.228H119.368V207.702H124.842V202.228Z"
          fill="#CA9158"
        />
        <path
          d="M124.842 191.246H119.368V196.754H124.842V191.246Z"
          fill="#CA9158"
        />
        <path
          d="M124.842 196.754H119.368V202.228H124.842V196.754Z"
          fill="#CA9158"
        />
        <path
          d="M124.842 180.298H119.368V185.772H124.842V180.298Z"
          fill="#CA9158"
        />
        <path
          d="M119.368 196.754H113.86V202.228H119.368V196.754Z"
          fill="#CA9158"
        />
        <path
          d="M119.368 202.228H113.86V207.702H119.368V202.228Z"
          fill="#CA9158"
        />
        <path
          d="M119.368 185.772H113.86V191.246H119.368V185.772Z"
          fill="#CA9158"
        />
        <path
          d="M119.368 180.298H113.86V185.772H119.368V180.298Z"
          fill="#CA9158"
        />
        <path
          d="M119.368 191.246H113.86V196.754H119.368V191.246Z"
          fill="#CA9158"
        />
        <path
          d="M124.842 174.79H119.368V180.298H124.842V174.79Z"
          fill="#CA9158"
        />
        <path
          d="M119.368 174.79H113.86V180.298H119.368V174.79Z"
          fill="#CA9158"
        />
        <path
          d="M119.368 169.316H113.86V174.79H119.368V169.316Z"
          fill="#CA9158"
        />
        <path
          d="M113.86 169.316H108.386V174.79H113.86V169.316Z"
          fill="#CA9158"
        />
        <path
          d="M119.368 163.842H113.86V169.316H119.368V163.842Z"
          fill="#CA9158"
        />
        <path
          d="M119.368 152.86H113.86V158.368H119.368V152.86Z"
          fill="#CA9158"
        />
        <path
          d="M119.368 158.368H113.86V163.842H119.368V158.368Z"
          fill="#CA9158"
        />
        <path
          d="M119.368 147.386H113.86V152.86H119.368V147.386Z"
          fill="#CA9158"
        />
        <path
          d="M119.368 141.912H113.86V147.386H119.368V141.912Z"
          fill="#CA9158"
        />
        <path
          d="M113.86 163.842H108.386V169.316H113.86V163.842Z"
          fill="#CA9158"
        />
        <path
          d="M113.86 158.368H108.386V163.842H113.86V158.368Z"
          fill="#CA9158"
        />
        <path
          d="M113.86 152.86H108.386V158.368H113.86V152.86Z"
          fill="#CA9158"
        />
        <path
          d="M113.86 147.386H108.386V152.86H113.86V147.386Z"
          fill="#CA9158"
        />
        <path
          d="M113.86 141.912H108.386V147.386H113.86V141.912Z"
          fill="#CA9158"
        />
        <path
          d="M141.298 240.614H135.79V246.088H141.298V240.614Z"
          fill="#F8AD33"
        />
        <path
          d="M141.298 224.158H135.79V229.632H141.298V224.158Z"
          fill="#F8AD33"
        />
        <path
          d="M141.298 218.684H135.79V224.158H141.298V218.684Z"
          fill="#F8AD33"
        />
        <path
          d="M141.298 229.632H135.79V235.14H141.298V229.632Z"
          fill="#F8AD33"
        />
        <path
          d="M141.298 207.702H135.79V213.21H141.298V207.702Z"
          fill="#F8AD33"
        />
        <path
          d="M141.298 235.14H135.79V240.614H141.298V235.14Z"
          fill="#F8AD33"
        />
        <path
          d="M141.298 213.21H135.79V218.684H141.298V213.21Z"
          fill="#F8AD33"
        />
        <path
          d="M135.79 240.614H130.316V246.088H135.79V240.614Z"
          fill="#F8AD33"
        />
        <path
          d="M135.79 218.684H130.316V224.158H135.79V218.684Z"
          fill="#F8AD33"
        />
        <path
          d="M135.79 235.14H130.316V240.614H135.79V235.14Z"
          fill="#F8AD33"
        />
        <path
          d="M135.79 229.632H130.316V235.14H135.79V229.632Z"
          fill="#F8AD33"
        />
        <path
          d="M135.79 224.158H130.316V229.632H135.79V224.158Z"
          fill="#F8AD33"
        />
        <path
          d="M135.79 213.21H130.316V218.684H135.79V213.21Z"
          fill="#F8AD33"
        />
        <path
          d="M135.79 207.702H130.316V213.21H135.79V207.702Z"
          fill="#F8AD33"
        />
        <path
          d="M124.842 152.86H119.368V158.368H124.842V152.86Z"
          fill="#F8AD33"
        />
        <path
          d="M124.842 147.386H119.368V152.86H124.842V147.386Z"
          fill="#F8AD33"
        />
        <path
          d="M124.842 141.912H119.368V147.386H124.842V141.912Z"
          fill="#F8AD33"
        />
        <path
          d="M124.842 169.316H119.368V174.79H124.842V169.316Z"
          fill="#F8AD33"
        />
        <path
          d="M124.842 163.842H119.368V169.316H124.842V163.842Z"
          fill="#F8AD33"
        />
        <path
          d="M124.842 158.368H119.368V163.842H124.842V158.368Z"
          fill="#F8AD33"
        />
        <path
          d="M135.79 185.772H130.316V191.246H135.79V185.772Z"
          fill="#F8AD33"
        />
        <path
          d="M135.79 180.298H130.316V185.772H135.79V180.298Z"
          fill="#F8AD33"
        />
        <path
          d="M135.79 174.79H130.316V180.298H135.79V174.79Z"
          fill="#F8AD33"
        />
        <path
          d="M135.79 196.754H130.316V202.228H135.79V196.754Z"
          fill="#F8AD33"
        />
        <path
          d="M135.79 191.246H130.316V196.754H135.79V191.246Z"
          fill="#F8AD33"
        />
        <path
          d="M135.79 202.228H130.316V207.702H135.79V202.228Z"
          fill="#F8AD33"
        />
        <path
          d="M130.316 196.754H124.842V202.228H130.316V196.754Z"
          fill="#F8AD33"
        />
        <path
          d="M130.316 191.246H124.842V196.754H130.316V191.246Z"
          fill="#F8AD33"
        />
        <path
          d="M130.316 202.228H124.842V207.702H130.316V202.228Z"
          fill="#F8AD33"
        />
        <path
          d="M130.316 180.298H124.842V185.772H130.316V180.298Z"
          fill="#F8AD33"
        />
        <path
          d="M130.316 174.79H124.842V180.298H130.316V174.79Z"
          fill="#F8AD33"
        />
        <path
          d="M130.316 185.772H124.842V191.246H130.316V185.772Z"
          fill="#F8AD33"
        />
        <path
          d="M207.088 169.316H201.614V174.79H207.088V169.316Z"
          fill="#010202"
        />
        <path
          d="M207.088 163.842H201.614V169.316H207.088V163.842Z"
          fill="#010202"
        />
        <path
          d="M207.088 158.368H201.614V163.842H207.088V158.368Z"
          fill="#010202"
        />
        <path
          d="M207.088 152.86H201.614V158.368H207.088V152.86Z"
          fill="#010202"
        />
        <path
          d="M207.088 147.386H201.614V152.86H207.088V147.386Z"
          fill="#010202"
        />
        <path
          d="M207.088 141.912H201.614V147.386H207.088V141.912Z"
          fill="#010202"
        />
        <path
          d="M207.088 136.404H201.614V141.912H207.088V136.404Z"
          fill="#010202"
        />
        <path
          d="M207.088 130.93H201.614V136.404H207.088V130.93Z"
          fill="#010202"
        />
        <path
          d="M207.088 125.456H201.614V130.93H207.088V125.456Z"
          fill="#010202"
        />
        <path
          d="M201.614 202.228H196.14V207.702H201.614V202.228Z"
          fill="#010202"
        />
        <path
          d="M201.614 196.754H196.14V202.228H201.614V196.754Z"
          fill="#010202"
        />
        <path
          d="M201.614 191.246H196.14V196.754H201.614V191.246Z"
          fill="#010202"
        />
        <path
          d="M201.614 185.772H196.14V191.246H201.614V185.772Z"
          fill="#010202"
        />
        <path
          d="M201.614 180.298H196.14V185.772H201.614V180.298Z"
          fill="#010202"
        />
        <path
          d="M201.614 174.79H196.14V180.298H201.614V174.79Z"
          fill="#010202"
        />
        <path
          d="M201.614 169.316H196.14V174.79H201.614V169.316Z"
          fill="#F8AC33"
        />
        <path
          d="M201.614 163.842H196.14V169.316H201.614V163.842Z"
          fill="#F8AC33"
        />
        <path
          d="M201.614 158.368H196.14V163.842H201.614V158.368Z"
          fill="#F8AC33"
        />
        <path
          d="M201.614 152.86H196.14V158.368H201.614V152.86Z"
          fill="#F8AC33"
        />
        <path
          d="M201.614 147.386H196.14V152.86H201.614V147.386Z"
          fill="#F8AC33"
        />
        <path
          d="M201.614 141.912H196.14V147.386H201.614V141.912Z"
          fill="#F8AC33"
        />
        <path
          d="M201.614 136.404H196.14V141.912H201.614V136.404Z"
          fill="white"
        />
        <path d="M201.614 130.93H196.14V136.404H201.614V130.93Z" fill="white" />
        <path
          d="M201.614 125.456H196.14V130.93H201.614V125.456Z"
          fill="white"
        />
        <path
          d="M201.614 119.982H196.14V125.456H201.614V119.982Z"
          fill="#010202"
        />
        <path
          d="M196.14 262.544H190.632V268.018H196.14V262.544Z"
          fill="#010202"
        />
        <path
          d="M196.14 257.07H190.632V262.544H196.14V257.07Z"
          fill="#010202"
        />
        <path
          d="M196.14 251.596H190.632V257.07H196.14V251.596Z"
          fill="#010202"
        />
        <path
          d="M196.14 246.088H190.632V251.596H196.14V246.088Z"
          fill="#010202"
        />
        <path
          d="M196.14 240.614H190.632V246.088H196.14V240.614Z"
          fill="#010202"
        />
        <path
          d="M196.14 235.14H190.632V240.614H196.14V235.14Z"
          fill="#010202"
        />
        <path
          d="M196.14 229.632H190.632V235.14H196.14V229.632Z"
          fill="#010202"
        />
        <path
          d="M196.14 224.158H190.632V229.632H196.14V224.158Z"
          fill="#010202"
        />
        <path
          d="M196.14 218.684H190.632V224.158H196.14V218.684Z"
          fill="#010202"
        />
        <path
          d="M196.14 213.21H190.632V218.684H196.14V213.21Z"
          fill="#010202"
        />
        <path
          d="M196.14 207.702H190.632V213.21H196.14V207.702Z"
          fill="#010202"
        />
        <path
          d="M196.14 202.228H190.632V207.702H196.14V202.228Z"
          fill="#F8AC33"
        />
        <path
          d="M196.14 196.754H190.632V202.228H196.14V196.754Z"
          fill="#F8AC33"
        />
        <path
          d="M196.14 191.246H190.632V196.754H196.14V191.246Z"
          fill="#F8AC33"
        />
        <path
          d="M196.14 185.772H190.632V191.246H196.14V185.772Z"
          fill="#F8AC33"
        />
        <path
          d="M196.14 180.298H190.632V185.772H196.14V180.298Z"
          fill="#F8AC33"
        />
        <path
          d="M196.14 174.79H190.632V180.298H196.14V174.79Z"
          fill="#F8AC33"
        />
        <path
          d="M196.14 169.316H190.632V174.79H196.14V169.316Z"
          fill="#F8AC33"
        />
        <path
          d="M196.14 163.842H190.632V169.316H196.14V163.842Z"
          fill="#F8AC33"
        />
        <path
          d="M196.14 158.368H190.632V163.842H196.14V158.368Z"
          fill="#F8AC33"
        />
        <path
          d="M196.14 152.86H190.632V158.368H196.14V152.86Z"
          fill="#F8AC33"
        />
        <path
          d="M196.14 147.386H190.632V152.86H196.14V147.386Z"
          fill="#F8AC33"
        />
        <path
          d="M196.14 141.912H190.632V147.386H196.14V141.912Z"
          fill="#F8AC33"
        />
        <path
          d="M196.14 136.404H190.632V141.912H196.14V136.404Z"
          fill="white"
        />
        <path d="M196.14 130.93H190.632V136.404H196.14V130.93Z" fill="white" />
        <path d="M196.14 125.456H190.632V130.93H196.14V125.456Z" fill="white" />
        <path
          d="M196.14 119.982H190.632V125.456H196.14V119.982Z"
          fill="#010202"
        />
        <path
          d="M190.632 268.018H185.158V273.526H190.632V268.018Z"
          fill="#010202"
        />
        <path
          d="M190.632 262.544H185.158V268.018H190.632V262.544Z"
          fill="white"
        />
        <path
          d="M190.632 257.07H185.158V262.544H190.632V257.07Z"
          fill="white"
        />
        <path
          d="M190.632 251.596H185.158V257.07H190.632V251.596Z"
          fill="white"
        />
        <path
          d="M190.632 246.088H185.158V251.596H190.632V246.088Z"
          fill="#DBDBDB"
        />
        <path
          d="M190.632 240.614H185.158V246.088H190.632V240.614Z"
          fill="#F8AC33"
        />
        <path
          d="M190.632 235.14H185.158V240.614H190.632V235.14Z"
          fill="#F8AC33"
        />
        <path
          d="M190.632 229.632H185.158V235.14H190.632V229.632Z"
          fill="#F8AC33"
        />
        <path
          d="M190.632 224.158H185.158V229.632H190.632V224.158Z"
          fill="#F8AC33"
        />
        <path
          d="M190.632 218.684H185.158V224.158H190.632V218.684Z"
          fill="#F8AC33"
        />
        <path
          d="M190.632 213.21H185.158V218.684H190.632V213.21Z"
          fill="#F8AC33"
        />
        <path
          d="M190.632 207.702H185.158V213.21H190.632V207.702Z"
          fill="#F8AC33"
        />
        <path
          d="M190.632 202.228H185.158V207.702H190.632V202.228Z"
          fill="#F8AC33"
        />
        <path
          d="M190.632 196.754H185.158V202.228H190.632V196.754Z"
          fill="#F8AC33"
        />
        <path
          d="M190.632 191.246H185.158V196.754H190.632V191.246Z"
          fill="#F8AC33"
        />
        <path
          d="M190.632 185.772H185.158V191.246H190.632V185.772Z"
          fill="#F8AC33"
        />
        <path
          d="M190.632 180.298H185.158V185.772H190.632V180.298Z"
          fill="#F8AC33"
        />
        <path
          d="M190.632 174.79H185.158V180.298H190.632V174.79Z"
          fill="#F8AC33"
        />
        <path
          d="M190.632 169.316H185.158V174.79H190.632V169.316Z"
          fill="#F8AC33"
        />
        <path
          d="M190.632 163.842H185.158V169.316H190.632V163.842Z"
          fill="#F8AC33"
        />
        <path
          d="M190.632 158.368H185.158V163.842H190.632V158.368Z"
          fill="#F8AC33"
        />
        <path
          d="M190.632 152.86H185.158V158.368H190.632V152.86Z"
          fill="#F8AC33"
        />
        <path
          d="M190.632 147.386H185.158V152.86H190.632V147.386Z"
          fill="#F8AC33"
        />
        <path
          d="M190.632 141.912H185.158V147.386H190.632V141.912Z"
          fill="#F8AC33"
        />
        <path
          d="M190.632 136.404H185.158V141.912H190.632V136.404Z"
          fill="white"
        />
        <path
          d="M190.632 130.93H185.158V136.404H190.632V130.93Z"
          fill="white"
        />
        <path
          d="M190.632 125.456H185.158V130.93H190.632V125.456Z"
          fill="white"
        />
        <path
          d="M190.632 119.982H185.158V125.456H190.632V119.982Z"
          fill="#010202"
        />
        <path
          d="M185.158 268.018H179.684V273.526H185.158V268.018Z"
          fill="#010202"
        />
        <path
          d="M185.158 262.544H179.684V268.018H185.158V262.544Z"
          fill="white"
        />
        <path
          d="M185.158 257.07H179.684V262.544H185.158V257.07Z"
          fill="white"
        />
        <path
          d="M185.158 251.596H179.684V257.07H185.158V251.596Z"
          fill="white"
        />
        <path
          d="M185.158 246.088H179.684V251.596H185.158V246.088Z"
          fill="#DBDBDB"
        />
        <path
          d="M185.158 240.614H179.684V246.088H185.158V240.614Z"
          fill="#F8AC33"
        />
        <path
          d="M185.158 235.14H179.684V240.614H185.158V235.14Z"
          fill="#F8AC33"
        />
        <path
          d="M185.158 229.632H179.684V235.14H185.158V229.632Z"
          fill="#F8AC33"
        />
        <path
          d="M185.158 224.158H179.684V229.632H185.158V224.158Z"
          fill="#F8AC33"
        />
        <path
          d="M185.158 218.684H179.684V224.158H185.158V218.684Z"
          fill="#F8AC33"
        />
        <path
          d="M185.158 213.21H179.684V218.684H185.158V213.21Z"
          fill="#F8AC33"
        />
        <path
          d="M185.158 207.702H179.684V213.21H185.158V207.702Z"
          fill="#F8AC33"
        />
        <path
          d="M185.158 202.228H179.684V207.702H185.158V202.228Z"
          fill="#F8AC33"
        />
        <path
          d="M185.158 196.754H179.684V202.228H185.158V196.754Z"
          fill="#FFEB64"
        />
        <path
          d="M185.158 191.246H179.684V196.754H185.158V191.246Z"
          fill="#FFEB64"
        />
        <path
          d="M185.158 185.772H179.684V191.246H185.158V185.772Z"
          fill="#F8AD33"
        />
        <path
          d="M185.158 180.298H179.684V185.772H185.158V180.298Z"
          fill="#FFEB64"
        />
        <path
          d="M185.158 174.79H179.684V180.298H185.158V174.79Z"
          fill="#FFEB64"
        />
        <path
          d="M185.158 169.316H179.684V174.79H185.158V169.316Z"
          fill="#FFEB64"
        />
        <path
          d="M185.158 163.842H179.684V169.316H185.158V163.842Z"
          fill="#FFEB64"
        />
        <path
          d="M185.158 158.368H179.684V163.842H185.158V158.368Z"
          fill="#FFEB64"
        />
        <path
          d="M185.158 152.86H179.684V158.368H185.158V152.86Z"
          fill="#FFEB64"
        />
        <path
          d="M185.158 147.386H179.684V152.86H185.158V147.386Z"
          fill="#FFEB64"
        />
        <path
          d="M185.158 141.912H179.684V147.386H185.158V141.912Z"
          fill="#FFEB64"
        />
        <path
          d="M185.158 136.404H179.684V141.912H185.158V136.404Z"
          fill="white"
        />
        <path
          d="M185.158 130.93H179.684V136.404H185.158V130.93Z"
          fill="white"
        />
        <path
          d="M185.158 125.456H179.684V130.93H185.158V125.456Z"
          fill="white"
        />
        <path
          d="M185.158 119.982H179.684V125.456H185.158V119.982Z"
          fill="#010202"
        />
        <path
          d="M179.684 268.018H174.21V273.526H179.684V268.018Z"
          fill="#010202"
        />
        <path
          d="M179.684 262.544H174.21V268.018H179.684V262.544Z"
          fill="white"
        />
        <path d="M179.684 257.07H174.21V262.544H179.684V257.07Z" fill="white" />
        <path
          d="M179.684 251.596H174.21V257.07H179.684V251.596Z"
          fill="white"
        />
        <path
          d="M179.684 246.088H174.21V251.596H179.684V246.088Z"
          fill="#DBDBDB"
        />
        <path
          d="M179.684 240.614H174.21V246.088H179.684V240.614Z"
          fill="#F8AC33"
        />
        <path
          d="M179.684 235.14H174.21V240.614H179.684V235.14Z"
          fill="#F8AC33"
        />
        <path
          d="M179.684 229.632H174.21V235.14H179.684V229.632Z"
          fill="#F8AC33"
        />
        <path
          d="M179.684 224.158H174.21V229.632H179.684V224.158Z"
          fill="#F8AC33"
        />
        <path
          d="M179.684 218.684H174.21V224.158H179.684V218.684Z"
          fill="#F8AC33"
        />
        <path
          d="M179.684 213.21H174.21V218.684H179.684V213.21Z"
          fill="#F8AC33"
        />
        <path
          d="M179.684 207.702H174.21V213.21H179.684V207.702Z"
          fill="#F8AC33"
        />
        <path
          d="M179.684 202.228H174.21V207.702H179.684V202.228Z"
          fill="#F8AC33"
        />
        <path
          d="M179.684 196.754H174.21V202.228H179.684V196.754Z"
          fill="#FFEB64"
        />
        <path
          d="M179.684 191.246H174.21V196.754H179.684V191.246Z"
          fill="#FFEB64"
        />
        <path
          d="M179.684 185.772H174.21V191.246H179.684V185.772Z"
          fill="#F8AD33"
        />
        <path
          d="M179.684 180.298H174.21V185.772H179.684V180.298Z"
          fill="#FFEB64"
        />
        <path
          d="M179.684 174.79H174.21V180.298H179.684V174.79Z"
          fill="#FFEB64"
        />
        <path
          d="M179.684 169.316H174.21V174.79H179.684V169.316Z"
          fill="#FFEB64"
        />
        <path
          d="M179.684 163.842H174.21V169.316H179.684V163.842Z"
          fill="#FFEB64"
        />
        <path
          d="M179.684 158.368H174.21V163.842H179.684V158.368Z"
          fill="#FFEB64"
        />
        <path
          d="M179.684 152.86H174.21V158.368H179.684V152.86Z"
          fill="#FFEB64"
        />
        <path
          d="M179.684 147.386H174.21V152.86H179.684V147.386Z"
          fill="#FFEB64"
        />
        <path
          d="M179.684 141.912H174.21V147.386H179.684V141.912Z"
          fill="#FFEB64"
        />
        <path
          d="M179.684 136.404H174.21V141.912H179.684V136.404Z"
          fill="white"
        />
        <path d="M179.684 130.93H174.21V136.404H179.684V130.93Z" fill="white" />
        <path
          d="M179.684 125.456H174.21V130.93H179.684V125.456Z"
          fill="white"
        />
        <path
          d="M179.684 119.982H174.21V125.456H179.684V119.982Z"
          fill="#010202"
        />
        <path
          d="M174.21 268.018H168.702V273.526H174.21V268.018Z"
          fill="#010202"
        />
        <path
          d="M174.21 262.544H168.702V268.018H174.21V262.544Z"
          fill="white"
        />
        <path d="M174.21 257.07H168.702V262.544H174.21V257.07Z" fill="white" />
        <path d="M174.21 251.596H168.702V257.07H174.21V251.596Z" fill="white" />
        <path
          d="M174.21 246.088H168.702V251.596H174.21V246.088Z"
          fill="#DBDBDB"
        />
        <path
          d="M174.21 240.614H168.702V246.088H174.21V240.614Z"
          fill="#F8AC33"
        />
        <path
          d="M174.21 235.14H168.702V240.614H174.21V235.14Z"
          fill="#F8AC33"
        />
        <path
          d="M174.21 229.632H168.702V235.14H174.21V229.632Z"
          fill="#F8AC33"
        />
        <path
          d="M174.21 224.158H168.702V229.632H174.21V224.158Z"
          fill="#F8AC33"
        />
        <path
          d="M174.21 218.684H168.702V224.158H174.21V218.684Z"
          fill="#F8AC33"
        />
        <path
          d="M174.21 213.21H168.702V218.684H174.21V213.21Z"
          fill="#F8AC33"
        />
        <path
          d="M174.21 207.702H168.702V213.21H174.21V207.702Z"
          fill="#F8AC33"
        />
        <path
          d="M174.21 202.228H168.702V207.702H174.21V202.228Z"
          fill="#F8AC33"
        />
        <path
          d="M174.21 196.754H168.702V202.228H174.21V196.754Z"
          fill="#F8AC33"
        />
        <path
          d="M174.21 191.246H168.702V196.754H174.21V191.246Z"
          fill="#F8AC33"
        />
        <path
          d="M174.21 185.772H168.702V191.246H174.21V185.772Z"
          fill="#F8AC33"
        />
        <path
          d="M174.21 180.298H168.702V185.772H174.21V180.298Z"
          fill="#F8AC33"
        />
        <path
          d="M174.21 174.79H168.702V180.298H174.21V174.79Z"
          fill="#F8AC33"
        />
        <path
          d="M174.21 169.316H168.702V174.79H174.21V169.316Z"
          fill="#F8AC33"
        />
        <path
          d="M174.21 163.842H168.702V169.316H174.21V163.842Z"
          fill="#F8AC33"
        />
        <path
          d="M174.21 158.368H168.702V163.842H174.21V158.368Z"
          fill="#F8AC33"
        />
        <path
          d="M174.21 152.86H168.702V158.368H174.21V152.86Z"
          fill="#F8AC33"
        />
        <path
          d="M174.21 147.386H168.702V152.86H174.21V147.386Z"
          fill="#F8AC33"
        />
        <path
          d="M174.21 141.912H168.702V147.386H174.21V141.912Z"
          fill="#F8AC33"
        />
        <path
          d="M174.21 136.404H168.702V141.912H174.21V136.404Z"
          fill="white"
        />
        <path d="M174.21 130.93H168.702V136.404H174.21V130.93Z" fill="white" />
        <path d="M174.21 125.456H168.702V130.93H174.21V125.456Z" fill="white" />
        <path
          d="M174.21 119.982H168.702V125.456H174.21V119.982Z"
          fill="#010202"
        />
        <path
          d="M168.702 268.018H163.228V273.526H168.702V268.018Z"
          fill="#010202"
        />
        <path
          d="M168.702 262.544H163.228V268.018H168.702V262.544Z"
          fill="white"
        />
        <path
          d="M168.702 257.07H163.228V262.544H168.702V257.07Z"
          fill="white"
        />
        <path
          d="M168.702 251.596H163.228V257.07H168.702V251.596Z"
          fill="white"
        />
        <path
          d="M168.702 246.088H163.228V251.596H168.702V246.088Z"
          fill="#DBDBDB"
        />
        <path
          d="M168.702 240.614H163.228V246.088H168.702V240.614Z"
          fill="#F8AC33"
        />
        <path
          d="M168.702 235.14H163.228V240.614H168.702V235.14Z"
          fill="#F8AC33"
        />
        <path
          d="M168.702 229.632H163.228V235.14H168.702V229.632Z"
          fill="#F8AC33"
        />
        <path
          d="M168.702 224.158H163.228V229.632H168.702V224.158Z"
          fill="#F8AC33"
        />
        <path
          d="M168.702 218.684H163.228V224.158H168.702V218.684Z"
          fill="#F8AC33"
        />
        <path
          d="M168.702 213.21H163.228V218.684H168.702V213.21Z"
          fill="#F8AC33"
        />
        <path
          d="M168.702 207.702H163.228V213.21H168.702V207.702Z"
          fill="#F8AC33"
        />
        <path
          d="M168.702 202.228H163.228V207.702H168.702V202.228Z"
          fill="#F8AC33"
        />
        <path
          d="M168.702 196.754H163.228V202.228H168.702V196.754Z"
          fill="#F8AC33"
        />
        <path
          d="M168.702 191.246H163.228V196.754H168.702V191.246Z"
          fill="#F8AC33"
        />
        <path
          d="M168.702 185.772H163.228V191.246H168.702V185.772Z"
          fill="#F8AC33"
        />
        <path
          d="M168.702 180.298H163.228V185.772H168.702V180.298Z"
          fill="#F8AC33"
        />
        <path
          d="M168.702 174.79H163.228V180.298H168.702V174.79Z"
          fill="#F8AC33"
        />
        <path
          d="M168.702 169.316H163.228V174.79H168.702V169.316Z"
          fill="#F8AC33"
        />
        <path
          d="M168.702 163.842H163.228V169.316H168.702V163.842Z"
          fill="#F8AC33"
        />
        <path
          d="M168.702 158.368H163.228V163.842H168.702V158.368Z"
          fill="#F8AC33"
        />
        <path
          d="M168.702 152.86H163.228V158.368H168.702V152.86Z"
          fill="#F8AC33"
        />
        <path
          d="M168.702 147.386H163.228V152.86H168.702V147.386Z"
          fill="#F8AC33"
        />
        <path
          d="M168.702 141.912H163.228V147.386H168.702V141.912Z"
          fill="#F8AC33"
        />
        <path
          d="M168.702 136.404H163.228V141.912H168.702V136.404Z"
          fill="white"
        />
        <path
          d="M168.702 130.93H163.228V136.404H168.702V130.93Z"
          fill="white"
        />
        <path
          d="M168.702 125.456H163.228V130.93H168.702V125.456Z"
          fill="white"
        />
        <path
          d="M168.702 119.982H163.228V125.456H168.702V119.982Z"
          fill="#010202"
        />
        <path
          d="M163.228 268.018H157.754V273.526H163.228V268.018Z"
          fill="#010202"
        />
        <path
          d="M163.228 262.544H157.754V268.018H163.228V262.544Z"
          fill="white"
        />
        <path
          d="M163.228 257.07H157.754V262.544H163.228V257.07Z"
          fill="white"
        />
        <path
          d="M163.228 251.596H157.754V257.07H163.228V251.596Z"
          fill="white"
        />
        <path
          d="M163.228 246.088H157.754V251.596H163.228V246.088Z"
          fill="#DBDBDB"
        />
        <path
          d="M163.228 240.614H157.754V246.088H163.228V240.614Z"
          fill="#F8AC33"
        />
        <path
          d="M163.228 235.14H157.754V240.614H163.228V235.14Z"
          fill="#F8AC33"
        />
        <path
          d="M163.228 229.632H157.754V235.14H163.228V229.632Z"
          fill="#F8AC33"
        />
        <path
          d="M163.228 224.158H157.754V229.632H163.228V224.158Z"
          fill="#F8AC33"
        />
        <path
          d="M163.228 218.684H157.754V224.158H163.228V218.684Z"
          fill="#F8AC33"
        />
        <path
          d="M163.228 213.21H157.754V218.684H163.228V213.21Z"
          fill="#F8AC33"
        />
        <path
          d="M163.228 207.702H157.754V213.21H163.228V207.702Z"
          fill="#F8AC33"
        />
        <path
          d="M163.228 202.228H157.754V207.702H163.228V202.228Z"
          fill="#F8AC33"
        />
        <path
          d="M163.228 196.754H157.754V202.228H163.228V196.754Z"
          fill="#F8AC33"
        />
        <path
          d="M163.228 191.246H157.754V196.754H163.228V191.246Z"
          fill="#F8AC33"
        />
        <path
          d="M163.228 185.772H157.754V191.246H163.228V185.772Z"
          fill="#F8AC33"
        />
        <path
          d="M163.228 180.298H157.754V185.772H163.228V180.298Z"
          fill="#F8AC33"
        />
        <path
          d="M163.228 174.79H157.754V180.298H163.228V174.79Z"
          fill="#F8AC33"
        />
        <path
          d="M163.228 169.316H157.754V174.79H163.228V169.316Z"
          fill="#F8AC33"
        />
        <path
          d="M163.228 163.842H157.754V169.316H163.228V163.842Z"
          fill="#F8AC33"
        />
        <path
          d="M163.228 158.368H157.754V163.842H163.228V158.368Z"
          fill="#F8AC33"
        />
        <path
          d="M163.228 152.86H157.754V158.368H163.228V152.86Z"
          fill="#F8AC33"
        />
        <path
          d="M163.228 147.386H157.754V152.86H163.228V147.386Z"
          fill="#F8AC33"
        />
        <path
          d="M163.228 141.912H157.754V147.386H163.228V141.912Z"
          fill="#F8AC33"
        />
        <path
          d="M163.228 136.404H157.754V141.912H163.228V136.404Z"
          fill="white"
        />
        <path
          d="M163.228 130.93H157.754V136.404H163.228V130.93Z"
          fill="white"
        />
        <path
          d="M163.228 125.456H157.754V130.93H163.228V125.456Z"
          fill="white"
        />
        <path
          d="M163.228 119.982H157.754V125.456H163.228V119.982Z"
          fill="#010202"
        />
        <path
          d="M157.754 268.018H152.246V273.526H157.754V268.018Z"
          fill="#010202"
        />
        <path
          d="M157.754 262.544H152.246V268.018H157.754V262.544Z"
          fill="white"
        />
        <path
          d="M157.754 257.07H152.246V262.544H157.754V257.07Z"
          fill="white"
        />
        <path
          d="M157.754 251.596H152.246V257.07H157.754V251.596Z"
          fill="white"
        />
        <path
          d="M157.754 246.088H152.246V251.596H157.754V246.088Z"
          fill="#BCBCBB"
        />
        <path
          d="M157.754 240.614H152.246V246.088H157.754V240.614Z"
          fill="#D6783F"
        />
        <path
          d="M157.754 235.14H152.246V240.614H157.754V235.14Z"
          fill="#D6783F"
        />
        <path
          d="M157.754 229.632H152.246V235.14H157.754V229.632Z"
          fill="#D6783F"
        />
        <path
          d="M157.754 224.158H152.246V229.632H157.754V224.158Z"
          fill="#D6783F"
        />
        <path
          d="M157.754 218.684H152.246V224.158H157.754V218.684Z"
          fill="#D6783F"
        />
        <path
          d="M157.754 213.21H152.246V218.684H157.754V213.21Z"
          fill="#D6783F"
        />
        <path
          d="M157.754 207.702H152.246V213.21H157.754V207.702Z"
          fill="#D6783F"
        />
        <path
          d="M157.754 202.228H152.246V207.702H157.754V202.228Z"
          fill="#D6783F"
        />
        <path
          d="M157.754 196.754H152.246V202.228H157.754V196.754Z"
          fill="#D6783F"
        />
        <path
          d="M157.754 191.246H152.246V196.754H157.754V191.246Z"
          fill="#D6783F"
        />
        <path
          d="M157.754 185.772H152.246V191.246H157.754V185.772Z"
          fill="#D6783F"
        />
        <path
          d="M157.754 180.298H152.246V185.772H157.754V180.298Z"
          fill="#D6783F"
        />
        <path
          d="M157.754 174.79H152.246V180.298H157.754V174.79Z"
          fill="#D6783F"
        />
        <path
          d="M157.754 169.316H152.246V174.79H157.754V169.316Z"
          fill="#D6783F"
        />
        <path
          d="M157.754 163.842H152.246V169.316H157.754V163.842Z"
          fill="#D6783F"
        />
        <path
          d="M157.754 158.368H152.246V163.842H157.754V158.368Z"
          fill="#D6783F"
        />
        <path
          d="M157.754 152.86H152.246V158.368H157.754V152.86Z"
          fill="#D6783F"
        />
        <path
          d="M157.754 147.386H152.246V152.86H157.754V147.386Z"
          fill="#D6783F"
        />
        <path
          d="M157.754 141.912H152.246V147.386H157.754V141.912Z"
          fill="#D6783F"
        />
        <path
          d="M157.754 136.404H152.246V141.912H157.754V136.404Z"
          fill="white"
        />
        <path
          d="M157.754 130.93H152.246V136.404H157.754V130.93Z"
          fill="white"
        />
        <path
          d="M157.754 125.456H152.246V130.93H157.754V125.456Z"
          fill="white"
        />
        <path
          d="M157.754 119.982H152.246V125.456H157.754V119.982Z"
          fill="#010202"
        />
        <path
          d="M152.246 268.018H146.772V273.526H152.246V268.018Z"
          fill="#010202"
        />
        <path
          d="M152.246 262.544H146.772V268.018H152.246V262.544Z"
          fill="white"
        />
        <path
          d="M152.246 257.07H146.772V262.544H152.246V257.07Z"
          fill="white"
        />
        <path
          d="M152.246 251.596H146.772V257.07H152.246V251.596Z"
          fill="white"
        />
        <path
          d="M152.246 246.088H146.772V251.596H152.246V246.088Z"
          fill="#BCBCBB"
        />
        <path
          d="M152.246 240.614H146.772V246.088H152.246V240.614Z"
          fill="#D6783F"
        />
        <path
          d="M152.246 235.14H146.772V240.614H152.246V235.14Z"
          fill="#D6783F"
        />
        <path
          d="M152.246 229.632H146.772V235.14H152.246V229.632Z"
          fill="#D6783F"
        />
        <path
          d="M152.246 224.158H146.772V229.632H152.246V224.158Z"
          fill="#D6783F"
        />
        <path
          d="M152.246 218.684H146.772V224.158H152.246V218.684Z"
          fill="#D6783F"
        />
        <path
          d="M152.246 213.21H146.772V218.684H152.246V213.21Z"
          fill="#D6783F"
        />
        <path
          d="M152.246 207.702H146.772V213.21H152.246V207.702Z"
          fill="#D6783F"
        />
        <path
          d="M152.246 202.228H146.772V207.702H152.246V202.228Z"
          fill="#D6783F"
        />
        <path
          d="M152.246 196.754H146.772V202.228H152.246V196.754Z"
          fill="#D6783F"
        />
        <path
          d="M152.246 191.246H146.772V196.754H152.246V191.246Z"
          fill="#D6783F"
        />
        <path
          d="M152.246 185.772H146.772V191.246H152.246V185.772Z"
          fill="#D6783F"
        />
        <path
          d="M152.246 180.298H146.772V185.772H152.246V180.298Z"
          fill="#D6783F"
        />
        <path
          d="M152.246 174.79H146.772V180.298H152.246V174.79Z"
          fill="#D6783F"
        />
        <path
          d="M152.246 169.316H146.772V174.79H152.246V169.316Z"
          fill="#D6783F"
        />
        <path
          d="M152.246 163.842H146.772V169.316H152.246V163.842Z"
          fill="#D6783F"
        />
        <path
          d="M152.246 158.368H146.772V163.842H152.246V158.368Z"
          fill="#D6783F"
        />
        <path
          d="M152.246 152.86H146.772V158.368H152.246V152.86Z"
          fill="#D6783F"
        />
        <path
          d="M152.246 147.386H146.772V152.86H152.246V147.386Z"
          fill="#D6783F"
        />
        <path
          d="M152.246 141.912H146.772V147.386H152.246V141.912Z"
          fill="#D6783F"
        />
        <path
          d="M152.246 136.404H146.772V141.912H152.246V136.404Z"
          fill="white"
        />
        <path
          d="M152.246 130.93H146.772V136.404H152.246V130.93Z"
          fill="white"
        />
        <path
          d="M152.246 125.456H146.772V130.93H152.246V125.456Z"
          fill="white"
        />
        <path
          d="M152.246 119.982H146.772V125.456H152.246V119.982Z"
          fill="#010202"
        />
        <path
          d="M146.772 268.018H141.298V273.526H146.772V268.018Z"
          fill="#010202"
        />
        <path
          d="M146.772 262.544H141.298V268.018H146.772V262.544Z"
          fill="white"
        />
        <path
          d="M146.772 257.07H141.298V262.544H146.772V257.07Z"
          fill="white"
        />
        <path
          d="M146.772 251.596H141.298V257.07H146.772V251.596Z"
          fill="white"
        />
        <path
          d="M146.772 246.088H141.298V251.596H146.772V246.088Z"
          fill="#BCBCBB"
        />
        <path
          d="M146.772 240.614H141.298V246.088H146.772V240.614Z"
          fill="#D6783F"
        />
        <path
          d="M146.772 235.14H141.298V240.614H146.772V235.14Z"
          fill="#D6783F"
        />
        <path
          d="M146.772 229.632H141.298V235.14H146.772V229.632Z"
          fill="#D6783F"
        />
        <path
          d="M146.772 224.158H141.298V229.632H146.772V224.158Z"
          fill="#D6783F"
        />
        <path
          d="M146.772 218.684H141.298V224.158H146.772V218.684Z"
          fill="#D6783F"
        />
        <path
          d="M146.772 213.21H141.298V218.684H146.772V213.21Z"
          fill="#D6783F"
        />
        <path
          d="M146.772 207.702H141.298V213.21H146.772V207.702Z"
          fill="#D6783F"
        />
        <path
          d="M146.772 202.228H141.298V207.702H146.772V202.228Z"
          fill="#D6783F"
        />
        <path
          d="M146.772 196.754H141.298V202.228H146.772V196.754Z"
          fill="#D6783F"
        />
        <path
          d="M146.772 191.246H141.298V196.754H146.772V191.246Z"
          fill="#D6783F"
        />
        <path
          d="M146.772 185.772H141.298V191.246H146.772V185.772Z"
          fill="#D6783F"
        />
        <path
          d="M146.772 180.298H141.298V185.772H146.772V180.298Z"
          fill="#D6783F"
        />
        <path
          d="M146.772 174.79H141.298V180.298H146.772V174.79Z"
          fill="#D6783F"
        />
        <path
          d="M146.772 169.316H141.298V174.79H146.772V169.316Z"
          fill="#D6783F"
        />
        <path
          d="M146.772 163.842H141.298V169.316H146.772V163.842Z"
          fill="#D6783F"
        />
        <path
          d="M146.772 158.368H141.298V163.842H146.772V158.368Z"
          fill="#D6783F"
        />
        <path
          d="M146.772 152.86H141.298V158.368H146.772V152.86Z"
          fill="#D6783F"
        />
        <path
          d="M146.772 147.386H141.298V152.86H146.772V147.386Z"
          fill="#D6783F"
        />
        <path
          d="M146.772 141.912H141.298V147.386H146.772V141.912Z"
          fill="#D6783F"
        />
        <path
          d="M146.772 136.404H141.298V141.912H146.772V136.404Z"
          fill="white"
        />
        <path
          d="M146.772 130.93H141.298V136.404H146.772V130.93Z"
          fill="white"
        />
        <path
          d="M146.772 125.456H141.298V130.93H146.772V125.456Z"
          fill="white"
        />
        <path
          d="M146.772 119.982H141.298V125.456H146.772V119.982Z"
          fill="#010202"
        />
        <path
          d="M141.298 268.018H135.79V273.526H141.298V268.018Z"
          fill="#010202"
        />
        <path
          d="M141.298 262.544H135.79V268.018H141.298V262.544Z"
          fill="white"
        />
        <path d="M141.298 257.07H135.79V262.544H141.298V257.07Z" fill="white" />
        <path
          d="M141.298 251.596H135.79V257.07H141.298V251.596Z"
          fill="white"
        />
        <path
          d="M141.298 246.088H135.79V251.596H141.298V246.088Z"
          fill="#BCBCBB"
        />
        <path
          d="M141.298 202.228H135.79V207.702H141.298V202.228Z"
          fill="#D6783F"
        />
        <path
          d="M141.298 196.754H135.79V202.228H141.298V196.754Z"
          fill="#D6783F"
        />
        <path
          d="M141.298 191.246H135.79V196.754H141.298V191.246Z"
          fill="#D6783F"
        />
        <path
          d="M141.298 185.772H135.79V191.246H141.298V185.772Z"
          fill="#D6783F"
        />
        <path
          d="M141.298 180.298H135.79V185.772H141.298V180.298Z"
          fill="#D6783F"
        />
        <path
          d="M141.298 174.79H135.79V180.298H141.298V174.79Z"
          fill="#D6783F"
        />
        <path
          d="M141.298 169.316H135.79V174.79H141.298V169.316Z"
          fill="#D6783F"
        />
        <path
          d="M141.298 163.842H135.79V169.316H141.298V163.842Z"
          fill="#D6783F"
        />
        <path
          d="M141.298 158.368H135.79V163.842H141.298V158.368Z"
          fill="#D6783F"
        />
        <path
          d="M141.298 152.86H135.79V158.368H141.298V152.86Z"
          fill="#D6783F"
        />
        <path
          d="M141.298 147.386H135.79V152.86H141.298V147.386Z"
          fill="#D6783F"
        />
        <path
          d="M141.298 141.912H135.79V147.386H141.298V141.912Z"
          fill="#D6783F"
        />
        <path
          d="M141.298 136.404H135.79V141.912H141.298V136.404Z"
          fill="white"
        />
        <path d="M141.298 130.93H135.79V136.404H141.298V130.93Z" fill="white" />
        <path
          d="M141.298 125.456H135.79V130.93H141.298V125.456Z"
          fill="white"
        />
        <path
          d="M141.298 119.982H135.79V125.456H141.298V119.982Z"
          fill="#010202"
        />
        <path
          d="M135.79 268.018H130.316V273.526H135.79V268.018Z"
          fill="#010202"
        />
        <path
          d="M135.79 262.544H130.316V268.018H135.79V262.544Z"
          fill="white"
        />
        <path d="M135.79 257.07H130.316V262.544H135.79V257.07Z" fill="white" />
        <path d="M135.79 251.596H130.316V257.07H135.79V251.596Z" fill="white" />
        <path
          d="M135.79 246.088H130.316V251.596H135.79V246.088Z"
          fill="#BCBCBB"
        />
        <path
          d="M135.79 169.316H130.316V174.79H135.79V169.316Z"
          fill="#D6783F"
        />
        <path
          d="M135.79 163.842H130.316V169.316H135.79V163.842Z"
          fill="#D6783F"
        />
        <path
          d="M135.79 158.368H130.316V163.842H135.79V158.368Z"
          fill="#D6783F"
        />
        <path
          d="M135.79 152.86H130.316V158.368H135.79V152.86Z"
          fill="#D6783F"
        />
        <path
          d="M135.79 147.386H130.316V152.86H135.79V147.386Z"
          fill="#D6783F"
        />
        <path
          d="M135.79 141.912H130.316V147.386H135.79V141.912Z"
          fill="#D6783F"
        />
        <path
          d="M135.79 136.404H130.316V141.912H135.79V136.404Z"
          fill="white"
        />
        <path d="M135.79 130.93H130.316V136.404H135.79V130.93Z" fill="white" />
        <path d="M135.79 125.456H130.316V130.93H135.79V125.456Z" fill="white" />
        <path
          d="M135.79 119.982H130.316V125.456H135.79V119.982Z"
          fill="#010202"
        />
        <path
          d="M130.316 268.018H124.842V273.526H130.316V268.018Z"
          fill="#010202"
        />
        <path
          d="M130.316 262.544H124.842V268.018H130.316V262.544Z"
          fill="white"
        />
        <path
          d="M130.316 257.07H124.842V262.544H130.316V257.07Z"
          fill="white"
        />
        <path
          d="M130.316 251.596H124.842V257.07H130.316V251.596Z"
          fill="white"
        />
        <path
          d="M130.316 246.088H124.842V251.596H130.316V246.088Z"
          fill="#BCBCBB"
        />
        <path
          d="M130.316 169.316H124.842V174.79H130.316V169.316Z"
          fill="#D6783F"
        />
        <path
          d="M130.316 163.842H124.842V169.316H130.316V163.842Z"
          fill="#D6783F"
        />
        <path
          d="M130.316 158.368H124.842V163.842H130.316V158.368Z"
          fill="#D6783F"
        />
        <path
          d="M130.316 152.86H124.842V158.368H130.316V152.86Z"
          fill="#D6783F"
        />
        <path
          d="M130.316 147.386H124.842V152.86H130.316V147.386Z"
          fill="#D6783F"
        />
        <path
          d="M130.316 141.912H124.842V147.386H130.316V141.912Z"
          fill="#D6783F"
        />
        <path
          d="M130.316 136.404H124.842V141.912H130.316V136.404Z"
          fill="white"
        />
        <path
          d="M130.316 130.93H124.842V136.404H130.316V130.93Z"
          fill="white"
        />
        <path
          d="M130.316 125.456H124.842V130.93H130.316V125.456Z"
          fill="white"
        />
        <path
          d="M130.316 119.982H124.842V125.456H130.316V119.982Z"
          fill="#010202"
        />
        <path
          d="M124.842 268.018H119.368V273.526H124.842V268.018Z"
          fill="#010202"
        />
        <path
          d="M124.842 262.544H119.368V268.018H124.842V262.544Z"
          fill="white"
        />
        <path
          d="M124.842 257.07H119.368V262.544H124.842V257.07Z"
          fill="white"
        />
        <path
          d="M124.842 251.596H119.368V257.07H124.842V251.596Z"
          fill="white"
        />
        <path
          d="M124.842 246.088H119.368V251.596H124.842V246.088Z"
          fill="#BCBCBB"
        />
        <path
          d="M124.842 136.404H119.368V141.912H124.842V136.404Z"
          fill="white"
        />
        <path
          d="M124.842 130.93H119.368V136.404H124.842V130.93Z"
          fill="white"
        />
        <path
          d="M124.842 125.456H119.368V130.93H124.842V125.456Z"
          fill="white"
        />
        <path
          d="M124.842 119.982H119.368V125.456H124.842V119.982Z"
          fill="#010202"
        />
        <path
          d="M119.368 262.544H113.86V268.018H119.368V262.544Z"
          fill="#010202"
        />
        <path
          d="M119.368 257.07H113.86V262.544H119.368V257.07Z"
          fill="#010202"
        />
        <path
          d="M119.368 251.596H113.86V257.07H119.368V251.596Z"
          fill="#010202"
        />
        <path
          d="M119.368 246.088H113.86V251.596H119.368V246.088Z"
          fill="#010202"
        />
        <path
          d="M119.368 240.614H113.86V246.088H119.368V240.614Z"
          fill="#010202"
        />
        <path
          d="M119.368 235.14H113.86V240.614H119.368V235.14Z"
          fill="#010202"
        />
        <path
          d="M119.368 229.632H113.86V235.14H119.368V229.632Z"
          fill="#010202"
        />
        <path
          d="M119.368 224.158H113.86V229.632H119.368V224.158Z"
          fill="#010202"
        />
        <path
          d="M119.368 218.684H113.86V224.158H119.368V218.684Z"
          fill="#010202"
        />
        <path
          d="M119.368 213.21H113.86V218.684H119.368V213.21Z"
          fill="#010202"
        />
        <path
          d="M119.368 207.702H113.86V213.21H119.368V207.702Z"
          fill="#010202"
        />
        <path
          d="M119.368 136.404H113.86V141.912H119.368V136.404Z"
          fill="white"
        />
        <path d="M119.368 130.93H113.86V136.404H119.368V130.93Z" fill="white" />
        <path
          d="M119.368 125.456H113.86V130.93H119.368V125.456Z"
          fill="white"
        />
        <path
          d="M119.368 119.982H113.86V125.456H119.368V119.982Z"
          fill="#010202"
        />
        <path
          d="M113.86 202.228H108.386V207.702H113.86V202.228Z"
          fill="#010202"
        />
        <path
          d="M113.86 196.754H108.386V202.228H113.86V196.754Z"
          fill="#010202"
        />
        <path
          d="M113.86 191.246H108.386V196.754H113.86V191.246Z"
          fill="#010202"
        />
        <path
          d="M113.86 185.772H108.386V191.246H113.86V185.772Z"
          fill="#010202"
        />
        <path
          d="M113.86 180.298H108.386V185.772H113.86V180.298Z"
          fill="#010202"
        />
        <path
          d="M113.86 174.79H108.386V180.298H113.86V174.79Z"
          fill="#010202"
        />
        <path
          d="M113.86 136.404H108.386V141.912H113.86V136.404Z"
          fill="white"
        />
        <path d="M113.86 130.93H108.386V136.404H113.86V130.93Z" fill="white" />
        <path d="M113.86 125.456H108.386V130.93H113.86V125.456Z" fill="white" />
        <path
          d="M113.86 119.982H108.386V125.456H113.86V119.982Z"
          fill="#010202"
        />
        <path
          d="M108.386 169.316H102.912V174.79H108.386V169.316Z"
          fill="#010202"
        />
        <path
          d="M108.386 163.842H102.912V169.316H108.386V163.842Z"
          fill="#010202"
        />
        <path
          d="M108.386 158.368H102.912V163.842H108.386V158.368Z"
          fill="#010202"
        />
        <path
          d="M108.386 152.86H102.912V158.368H108.386V152.86Z"
          fill="#010202"
        />
        <path
          d="M108.386 147.386H102.912V152.86H108.386V147.386Z"
          fill="#010202"
        />
        <path
          d="M108.386 141.912H102.912V147.386H108.386V141.912Z"
          fill="#010202"
        />
        <path
          d="M108.386 136.404H102.912V141.912H108.386V136.404Z"
          fill="#010202"
        />
        <path
          d="M108.386 130.93H102.912V136.404H108.386V130.93Z"
          fill="#010202"
        />
        <path
          d="M108.386 125.456H102.912V130.93H108.386V125.456Z"
          fill="#010202"
        />
      </svg>
    </div>

    <img
      class="first-img"
      data-scroll-speed="2"
      src="assets/images/kouterweekend.jpg"
      alt="kouterweekend"
    />

    <div
      data-aos="fade-up"
      data-aos-duration="500"
      data-aos-delay="100"
      data-aos-easing="ease-in-out"
    >
      <p>
        Als jeugdhuis zijn we steeds op zoek naar gemotiveerde kernleden om
        samen het jeugdhuis draaiende te houden.
      </p>

      <p>
        Kom gerust eens langs in ons jeugdhuis en leer onze kernleden kennen.
        Als het jou wat lijkt kan je altijd ons bestuur of één van onze
        kernleden aanspreken.
      </p>
    </div>
  </div>
</div>
