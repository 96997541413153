import { Component, OnInit } from "@angular/core";
import * as AOS from "aos";
import * as $ from "jquery";
import { AppComponent } from "../app.component";

@Component({
  selector: "app-events",
  templateUrl: "./events.component.html",
  styleUrls: ["./events.component.scss"]
})
export class EventsComponent implements OnInit {
  constructor(private appComponent: AppComponent) {}

  ngOnInit(): void {
    this.appComponent.footerHome = false;
    this.appComponent.homeVisited = true;
    AOS.init();
    $.fn.moveIt = function() {
      var $window = $(window);
      var instances = [];

      $(this).each(function() {
        instances.push(new moveItItem($(this)));
      });

      window.addEventListener(
        "scroll",
        function() {
          var scrollTop = $window.scrollTop();
          instances.forEach(function(inst) {
            inst.update(scrollTop);
          });
        },
        { passive: true }
      );
    };

    var moveItItem = function(el) {
      this.el = $(el);
      this.speed = parseInt(this.el.attr("data-scroll-speed"));
    };

    moveItItem.prototype.update = function(scrollTop) {
      this.el.css(
        "transform",
        "translateY(" + -(scrollTop / this.speed) + "px)"
      );
    };

    // Initialization
    $(function() {
      $("[data-scroll-speed]").moveIt();
    });
  }
}
