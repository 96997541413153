<div class="container">
  <h1
    data-aos="fade-down"
    data-aos-duration="500"
    data-aos-delay="100"
    data-aos-easing="ease-in-out"
  >
    Meet the kern
  </h1>

  <div class="team-members">
    <div class="row">
      <div
        *ngFor="let teamMember of teamMembers; index as i"
        class="person col"
        data-aos="fade-up"
        data-aos-delay="200"
        data-aos-easing="ease-in-out"
      >
        <img
          class="picture"
          src="{{ base.baseUrlApiCartoon + teamMember.cartoon.url }}"
          alt="{{ teamMember.name }}"
        />
        <h4>{{ teamMember.firstname }} {{ teamMember.lastname }}</h4>
        <h5 *ngIf="teamMember.function != ''">{{ teamMember.function }}</h5>
      </div>
    </div>
  </div>
</div>
